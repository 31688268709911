
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import App from 'next/app';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import { OverlayProvider } from '@react-aria/overlays';
import { SSRProvider } from '@react-aria/ssr';
// Styles
import '../styles/globals.css';
// Layouts
import DefaultLayout from '@/layouts/default';
// Providers
import { AnalyticsProvider } from '@/providers/analytics';
import { UserProvider } from '@/providers/user';
import { CookieProvider } from '@/providers/cookie';
import { ScrollProvider } from '@/providers/scroll';
import { ExperimentsProvider } from '@/providers/experiments';
import { LastNewsContext } from '@/providers/last-news';
// Assets & utils
import { fetcher } from '@/services/api/core';
// Components
import Head from '@/components/Head';
import ErrorBoundary from '@/components/ErrorBoundary';
import CookieBanner from '@/components/CookieBanner';
import { getLastNews } from '@/utils/news';
const EmptyLayout = ({ children }) => children;
const setAppHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
};
function MyApp({ Component, initProps, pageProps }) {
    const Layout = !Component.skipLayout
        ? Component.getLayout || Component.layout || DefaultLayout
        : EmptyLayout;
    useEffect(() => {
        setAppHeight();
        window.addEventListener('resize', setAppHeight);
        return () => {
            window.removeEventListener('resize', setAppHeight);
        };
    }, []);
    const handleResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);
    return (<SWRConfig value={{ fetcher, errorRetryCount: 2 }}>
      <CookieProvider>
        <UserProvider value={pageProps.user}>
          <ExperimentsProvider experiments={pageProps.experiments || {}} flags={pageProps.flags || {}}>
            <AnalyticsProvider>
              <ErrorBoundary>
                <SSRProvider>
                  <OverlayProvider>
                    <ScrollProvider>
                      <LastNewsContext.Provider value={initProps?.lastNews || pageProps?.lastNews || []}>
                        <Layout>
                          <Head>
                            {process.env.NEXT_PUBLIC_VERCEL_ENV ===
            'preview' && (<meta name="robots" content="noindex"/>)}
                            <link rel="preconnect" href="https://use.typekit.net"/>
                            <link rel="preconnect" href="https://p.typekit.net"/>
                          </Head>
                          <Component {...pageProps}/>
                          <CookieBanner />
                        </Layout>
                      </LastNewsContext.Provider>
                    </ScrollProvider>
                  </OverlayProvider>
                </SSRProvider>
              </ErrorBoundary>
            </AnalyticsProvider>
          </ExperimentsProvider>
        </UserProvider>
      </CookieProvider>
    </SWRConfig>);
}
MyApp.getInitialProps = async (context) => {
    const itemPromise = getLastNews(context.ctx);
    const props = await App.getInitialProps(context);
    return {
        ...props,
        initProps: {
            lastNews: (await itemPromise) || [],
        },
    };
};
const __Next_Translate__Page__1927154828a__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1927154828a__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  